import clsx from 'clsx';
import Lottie from '@components/bridge/bridge/Lottie';
import logoDark from '@components/bridge/bridge/animations/logo/dark.json';
import React, { useState } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import Logo from '@components/bridge/bridge/Logo';
export default function ConnectLayout({
  children,
  whitelabel
}) {
  const router = useRouter();
  const lottie = {
    animationTiming: {
      t1: 0,
      t2: 3,
      t3: 3 + 12 / 25,
      t4: 7.0 + 15 / 25,
      frame: 25
    },
    config: {
      autoplay: false,
      animationData: logoDark,
      renderer: 'svg'
    },
    eventListeners: [{
      eventName: 'DOMLoaded',
      callback: () => {
        setSegments([lottie.animationTiming.t3 * lottie.animationTiming.frame, lottie.animationTiming.t4 * lottie.animationTiming.frame]);
      }
    }]
  };
  const [segments, setSegments] = useState([lottie.animationTiming.t1 * lottie.animationTiming.frame, lottie.animationTiming.t2 * lottie.animationTiming.frame]);
  return <>
            {router.route.includes('/register') ? <>
                    {/* <Lottie options={lottie.config} segments={segments} eventListeners={lottie.eventListeners} height={150} width={300} /> */}
                    <a className={clsx('register:logo')}>
                        <Logo whitelabel={whitelabel} router={router} />
                    </a>
                    {children}
                </> : <>
                    <Link href={`${router.route.includes('/connect/') ? '/connect' : 'https://danim.com'}`}>
                        <a className={clsx('connect:logo /custom')}>
                            <Logo whitelabel={whitelabel} router={router} />
                            {/* { */}
                            {/*    whitelabel?.logo?.src ? <Logo whitelabel={whitelabel} router={router} /> : null */}
                            {/*    // <Lottie */}
                            {/*    //     options={lottie.config} */}
                            {/*    //     segments={segments} */}
                            {/*    //     eventListeners={lottie.eventListeners} */}
                            {/*    //     height={150} */}
                            {/*    //     width={300} */}
                            {/*    // /> */}
                            {/* } */}
                        </a>
                    </Link>
                    {children}
                </>}
        </>;
}